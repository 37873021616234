/*.load {*/
/*  position: absolute;*/
/*  top: 50%;*/
/*  left: 50%;*/
/*  transform: translate(-50%, -50%);*/
/*  !* change these sizes to fit into your project *!*/
/*  width: 100px;*/
/*  height: 100px;*/
/*}*/
/*.load hr {*/
/*  border: 0;*/
/*  margin: 0;*/
/*  width: 40%;*/
/*  height: 40%;*/
/*  position: absolute;*/
/*  border-radius: 50%;*/
/*  animation: spin 2s ease infinite; !* Change animation duration to 4s *!*/
/*}*/

/*.load :first-child {*/
/*  background: #19a68c;*/
/*  animation-delay: -1.5s; !* Adjust delay accordingly *!*/
/*}*/
/*.load :nth-child(2) {*/
/*  background: #f63d3a;*/
/*  animation-delay: -1s; !* Adjust delay accordingly *!*/
/*}*/
/*.load :nth-child(3) {*/
/*  background: #fda543;*/
/*  animation-delay: -0.5s; !* Adjust delay accordingly *!*/
/*}*/
/*.load :last-child {*/
/*  background: #193b48;*/
/*}*/

/*@keyframes spin {*/
/*  0%, 100% {*/
/*    transform: translate(0);*/
/*  }*/
/*  25% {*/
/*    transform: translate(160%);*/
/*  }*/
/*  50% {*/
/*    transform: translate(160%, 160%);*/
/*  }*/
/*  75% {*/
/*    transform: translate(0, 160%);*/
/*  }*/
/*}*/
.preloader {
  background: #fff url('../../../../public/assets/images/Shopping Cart.gif') no-repeat scroll center center;
  height: 100%;
  left: 0;
  overflow: visible;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 9999999;
}